export default [
  {
    key: "otherNo",
    label: "เลขที่",
    thStyle:{color:"white", textAlign: "center"}
  },
  { key: "fullname", label: "ผู้เบิก", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "otherDescription", label: "รายละเอียด", sortable: true ,thStyle:{color:"white",width: "20% !important", textAlign: "center",}},
  { key: "otherDate", label: "วันที่ขอเบิก", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "otherRentalAmount", label: "ค่าที่พัก", sortable: false , thStyle:{color:"white", textAlign: "center"}},
  {
    key: "otherExpensesPerDayAmount",
    label: "ค่าเบี้ยเลี้ยง",
    sortable: false,
    thStyle: {  textAlign: "center" ,color:"white"},
  },
  { key: "otherGasOil", label: "ค่าน้ำมัน", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  { key: "otherPayTotal", label: "ค่าใช่จ่ายอื่นๆ", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  { key: "otherTotal", label: "รวม", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  { key: "processNo", label: "สถานะ", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  
];
