<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>รายงานเบิกค่าเดินทาง</b></h3>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center"> </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">
        <!-- START SECTION FORM วันเดินทาง--->
        <b-card>
          <!--- START ROW วันที่ --->
          <b-row>
            <b-col md="3" class="">
              <label>วันที่เริ่มต้น (เปิดใบงาน)</label>
              <b-form-datepicker
                id="example-datepicker"
                locale="th-TH"
                v-model="dateStart"
                show-decade-nav
                class="mb-1"
                size="sm"
                reset-button
              />
            </b-col>
            <b-col md="3" class="">
              <label>วันที่สิ้นสุด</label>
              <b-form-datepicker
                id="example-datepicker2"
                locale="th-TH"
                v-model="dateEnd"
                show-decade-nav
                class="mb-1"
                size="sm"
                reset-button
              />
            </b-col>
            <b-col md="3" class="">
              <label>ผู้รับงาน (ช่าง)</label>
              <v-select
                id="brand"
                v-model="employeeSelected"
                label="fullname"
                :options="employeeOption"
                class="select-size-sm"
                :clearable="false"
              />
            </b-col>
            <b-col md="3" class="">
              <label>สถานะใบงาน</label>
              <v-select
                id="brand"
                v-model="processSelected"
                label="title"
                :options="processOption"
                :clearable="false"
                class="select-size-sm"
              />
            </b-col>
          </b-row>
          <!--- END วันที่ --->

          <b-row class="">
            <b-col class="d-flex justify-content-end">
              <b-button
                variant="outline-primary"
                size="md"
                style="height: 35px"
                class="mr-2"
                
                @click="clearSearch"
              >
                <span> Clear Search</span>              
              </b-button>

              <b-button
                variant="outline-primary"
                size="md"
                style="height: 35px"
                class="mr-1"
                :disabled="isLoading || items.length == 0"
                @click="buildFieldExcel"
                title="คลิกปุ่มค้นหาข้อมูลก่อนทำการ Export"
              >
                <span v-if="!isLoading">
                  <export-excel
                    :data="items"
                    :fields="json_fields"
                    worksheet="My Worksheet"
                    name="Incentive Ot.xls"
                  >
                    Export <feather-icon icon="FileTextIcon" />
                  </export-excel>
                </span>

                <span v-else>
                  <b-spinner label="Text Centered" small class="mr-1" />
                  Exporting...
                </span>
              </b-button>
              
              
              <b-button
                variant="primary"
                size="md"
                style="height: 35px"
                class="mr-1"
                :disabled="isLoading"
                @click="onSearch"
              >
                <span v-if="!isLoading"> ค้นหา </span>

                <span v-else>
                  <b-spinner label="Text Centered" small class="mr-1" />
                  Exporting...
                </span>
              </b-button>

            </b-col>
          </b-row>
        </b-card>
        <!-- END SECTION FORM วันเดินทาง--->
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table
          style="font-size: 12px"
          class="shadow"
          small
          striped
          bordered
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"                    
          :busy="isLoading"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>

          <template #cell(processNo)="row" >           
                <center>
                  <b-badge pill v-if="row.item.processNo>=4" variant="success">{{row.item.processName}}</b-badge>
                  <b-badge pill v-else-if="row.item.processNo==0" variant="danger">กรอกเอกสาร</b-badge>
                  <b-badge pill v-else variant="info">{{row.item.processName}}</b-badge>
              </center>
          </template>

          <template #cell(selected)="{ rowSelected }"></template>
          <template #cell(openTicketDate)="row">
            {{ showDate(row.item.openTicketDate) }}
          </template>
        </b-table>
      </b-col>
      <b-col cols="2">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>

      <b-col cols="10" class="d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          variant="secondary"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BTable,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BPagination,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BThead,
  BTbody,
  BFormRadioGroup,
  BSpinner,
  BBadge,
  BFormSelect
} from "bootstrap-vue";
import vSelect from "vue-select";
import UserService from "@/services/user";
import AccountingReportService from "@/services/report/accounting";
import Field from "./field";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormDatepicker,
    BPagination,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BThead,
    BTbody,
    BFormRadioGroup,
    vSelect,
    BSpinner,
    BBadge,
    BFormSelect
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      employeeSelected: { fullname: "เลือกทั้งหมด", userId: 0 },
      processSelected: { id: -1, title: "เลือกทั้งหมด", value: -1 },
      employeeOption: [],
      processOption: [
        { id: -1, title: "เลือกทั้งหมด", value: -1 },
        { id: 0, title: "กรอกเอกสาร", value: 0 },
        { id: 1, title: "กำลังตรวจสอบเอกสาร", value: 1 },
        { id: 2, title: "ตรวจสอบยอดเงิน", value: 2 },
        { id: 3, title: "รอโอนเงิน", value: 3 },
        { id: 4, title: "ได้รับเงินแล้ว", value: 4 },
      ],
      ownerSelected: { ownerName: "เลือกทั้งหมด", id: 0 },
      ownerOption: [],
      companySelected: {},
      companyOption: [],
      subCompanySelected: {},
      subCompanyOption: [],
      branchOption: [],
      branchSelected: {},
      dateStart: "",
      dateEnd: "",
      isLoading: false,
      json_fields: {},
      fields: Field,
      items: [],
    };
  },

  computed :{
    reportPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("RP004")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
  },

  created() {
    this.getUsers();    
  },
  methods: {
    async onSearch() {
      this.isLoading = true;
      let queryString = [];
      if (this.dateStart && this.dateEnd) {
        queryString.push(`dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`);
      }

      if (this.processSelected.value !== -1) {
        queryString.push(`processNo=${this.processSelected.value}`);
      }

      if (this.employeeSelected.userId !== 0) {
        queryString.push(`assignTo=${this.employeeSelected.userId}`);
      }

      if (this.branchSelected.id && this.ownerSelected.id !== 0) {
        queryString.push(`customerId=${this.branchSelected.id}`);
      }

      const joined = queryString.join("&");

      const result = await AccountingReportService.GetAccountingReport(joined)
      this.items = result.data.data;
      this.buildFieldExcel()
      this.isLoading = false;
    },

    async getUsers() {
      const result = await UserService.GetUserList({},"type=employee");
      this.employeeOption.push({ fullname: "เลือกทั้งหมด", userId: 0 });
      this.employeeOption = [...this.employeeOption, ...result.data.data];
      this.employeeSelected = { fullname: "เลือกทั้งหมด", userId: 0 };
    },   
    clearSearch(){
      this.dateStart =  ""
      this.dateEnd = ""
      this.employeeSelected = { fullname: "เลือกทั้งหมด", userId: 0 }
      this.processSelected = { id: -1, title: "เลือกทั้งหมด", value: -1 }
    },
    buildFieldExcel() {
      let obj = {};
      Field.forEach((f) => {       
          obj[f.label] = f.key;
       
      });

      this.json_fields = obj;
    },
    showDate(date) {
      return moment(date).format("DD-MM-yyyy HH:mm");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  mounted(){
    this.totalRows = this.items.length;
  }
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
